import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user", "userAuthenticated", "userPlan", "userCurrency"]),
    userPlanName() {
      if (!this.userPlan) return;

      const name = this.$t(
        `page.checkout_page.section.global_${this.userPlan.code}_section.name`
      );

      return name[0].toUpperCase() + name.slice(1);
    },
  },
};
